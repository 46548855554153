import React from 'react';

export interface MemberParam {
  groupName?: string;
  castName: string;
  twitterUrl: string;
  imageUrl: String;
}

const MemberModal: React.FC<MemberParam> = ({ groupName, castName, twitterUrl, imageUrl }) => {
  return (
    <a className="member_image" href={twitterUrl} target="_blank" rel="noopener noreferrer">
      <div className="image-wrap">
        <picture>
          <source
            type="image/webp"
            srcSet={`${imageUrl}?w=328&q=75&fm=webp&dpr=2`}
            width="328"
            height="505"
            loading="lazy"
          />
          <img
            src={`${imageUrl}?w=328&q=75&fm=jpg&dpr=2`}
            alt={groupName ? `${groupName} | ${castName}` : castName}
            width="328"
            height="505"
            loading="lazy"
          />
        </picture>
      </div>
    </a>
  );
};

export default MemberModal;
