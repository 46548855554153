import React from 'react';
import { css } from '@emotion/react';

export interface sectionTitles {
  enText: String;
  jaText?: String;
  textWhite?: Boolean;
}

const SectionBlackTitle: React.FC<sectionTitles> = ({ enText, jaText, textWhite = false }) => {
  const titleClass = textWhite ? 'section-title-wrap c-white' : 'section-title-wrap';
  return (
    <div className={titleClass} css={titleStyle}>
      <p className="section-title-en">{enText}</p>
      <h2 className="section-title-ja">{jaText}</h2>
    </div>
  );
};

const titleStyle = css`
  text-align: center;
  margin: 0 0 2.5rem;
  .section-title-en {
    font-size: 2.15em;
    line-height: 1.5;
    letter-spacing: 0.12em;
    font-weight: 900;
    font-family: 'Montserrat', serif;
    color: #3b3b3b;
    padding: 0 0 20px;
    box-sizing: border-box;
    position: relative;

    &::before {
      content: '';
      width: 90px;
      height: 3px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0.7rem;
      margin: auto;
      background-color: #3b3b3b;
    }
  }
  .section-title-ja {
    font-size: 1.1em;
    line-height: 1.5;
    color: transparent;
    font-weight: 700;
    background-color: #000;
    text-shadow: rgba(255, 255, 255, 0.5) 0 5px 6px, rgba(255, 255, 255, 0.2) 1px 3px 3px;
    -webkit-background-clip: text;
  }

  &.c-white {
    .section-title-en,
    .section-title-ja {
      text-shadow: 0 0 5px #ff009f, 0 0 15px #d70f8b, 0 0 20px #b7167a, 0 0 40px #b7167a, 0 0 60px #fcdee5,
        0 0 10px #fcdee5, 0 0 98px #fcdee5;
      color: #fff;
    }

    .section-title-en {
      &::before {
        box-shadow: 0 0 5px #ff009f, 0 0 15px #d70f8b, 0 0 20px #b7167a, 0 0 40px #b7167a, 0 0 60px #fcdee5,
          0 0 10px #fcdee5, 0 0 98px #fcdee5;
        background: #fff;
      }
    }
  }

  @media (max-width: 767px) {
    margin: 0 0 30px;
    .section-title-en {
      font-size: 1.8em;
      padding: 0 0 14px;
      &::before {
        width: 45px;
        height: 3px;
        bottom: 8px;
      }
    }

    .section-title-ja {
      font-size: 1em;
    }
  }
`;

export default SectionBlackTitle;
