import { css } from '@emotion/react';

import p1_Background from '../../images/4th/bg_image_other.png';
import p1_Background2x from '../../images/4th/bg_image_other@2x.png';

import p1_Background_webp from '../../images/4th/bg_image_other.webp';
import p1_Background2x_webp from '../../images/4th//bg_image_other@2x.webp';

import p1_Background_cover from '../../images/bg_image_other.jpg';
import p1_Background2x_cover from '../../images/bg_image_other@2x.jpg';

import p1_Background_webp_cover from '../../images/bg_image_other.webp';
import p1_Background2x_webp_cover from '../../images//bg_image_other@2x.webp';

import p2_Background from '../../images/bg_image_blue.jpg';
import p2_Background2x from '../../images/bg_image_blue@2x.jpg';

import p2_Background_webp from '../../images/bg_image_blue.webp';
import p2_Background2x_webp from '../../images/bg_image_blue@2x.webp';

import p3_Background from '../../images/5th/bg_image_black.jpg';
import p3_Background2x from '../../images/5th/bg_image_black@2x.jpg';

import p3_Background_webp from '../../images/5th/bg_image_black.webp';
import p3_Background2x_webp from '../../images/5th/bg_image_black@2x.webp';

import p3_Background_cover from '../../images/5th/bg_image_black.jpg';
import p3_Background2x_cover from '../../images/5th/bg_image_black@2x.jpg';

import p3_Background_webp_cover from '../../images/5th/bg_image_black.webp';
import p3_Background2x_webp_cover from '../../images/5th/bg_image_black@2x.webp';

export const bgPattern1 = css`
  background-size: cover;
  background-image: url(${p1_Background});
  background-image: image-set(
    url(${p1_Background_cover}) 1x,
    url(${p1_Background2x_cover}) 2x
  );
  background-image: -webkit-image-set(
    url(${p1_Background_webp_cover}) 1x,
    url(${p1_Background2x_webp_cover}) 2x
  );
  background-repeat: no-repeat;
  background-position: bottom;

  &.contain-image {
    background-size: contain;
    background-image: url(${p1_Background});
    background-image: image-set(
      url(${p1_Background}) 1x,
      url(${p1_Background2x}) 2x
    );
    background-image: -webkit-image-set(
      url(${p1_Background_webp}) 1x,
      url(${p1_Background2x_webp}) 2x
    );
    background-color: #f8fafc;
  }

  &.about_sec {
    padding: 80px 0 90px;
  }
}
`;

export const bgPattern2 = css`
  background-size: cover;
  background-image: url(${p2_Background});
  background-image: image-set(url(${p2_Background}) 1x, url(${p2_Background2x}) 2x);
  background-image: -webkit-image-set(url(${p2_Background_webp}) 1x, url(${p2_Background2x_webp}) 2x);
`;

export const bgPattern3 = css`
  background-size: cover;
  background-image: url(${p3_Background});
  background-image: image-set(
    url(${p3_Background_cover}) 1x,
    url(${p3_Background2x_cover}) 2x
  );
  background-image: -webkit-image-set(
    url(${p3_Background_webp_cover}) 1x,
    url(${p3_Background2x_webp_cover}) 2x
  );
  background-repeat: repeat-y;
  background-position: top center;
  background-size: contain;

  &.contain-image {
    background-size: contain;
    background-image: url(${p3_Background});
    background-image: image-set(
      url(${p3_Background}) 1x,
      url(${p3_Background2x}) 2x
    );
    background-image: -webkit-image-set(
      url(${p3_Background_webp}) 1x,
      url(${p3_Background2x_webp}) 2x
    );
    background-color: #f8fafc;
  }
}
`;
